import React from "react";
import { Avatar, Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { Check, Cancel } from "@mui/icons-material";
import moment from "moment";
import { BAKER_PIC_FOLDER, STORAGE_BUCKET, bakeStreetSquareLogo, IMG_PLACEHOLDER } from "../../common/component/constants";
import currency from "currency.js";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import OrderProgress from "./OrderProgress";
import OrderPrint from "./OrderPrint";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

export default function OrderSummary(props) {
  const { order } = props;

  const printed = order?.activity?.print;
  const lastPrintTs = printed && printed[printed.length - 1].ts;

  let image = `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`;
  if (order.items && order.items.length > 0) {
    for (const item of order.items) {
      if (item.images && item.images.length > 0) {
        image = item.images[0];
        break;
      }
    }
  } else if (order.details && order.details.pics) {
    image = order.details.pics[0];
  }

  return (
    <Paper elevation={0} sx={{ marginBottom: "1rem" }}>
      <Box px={2} pt={2} pb={1}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={3}>
            <img src={image} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
          </Grid>
          <Grid item sm={8} xs={9}>
            <Typography variant="h6" gutterBottom>
              {order.customer.firstName || order.customer.name} {order.customer.lastName}
              {order.test && <Chip color="info" label="test" size="small" />}
              {(order.status === "pending" || order.status === "pending_review") && <Chip color="error" label="pending" size="small" />}
            </Typography>
            <Box style={{ color: "#888" }}>
              <Box>
                <Typography
                  variant="overline"
                  style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                >
                  <EventOutlinedIcon style={{ fontSize: "1.2rem" }} />
                  {moment(order.fulfillment.date).format("ddd, MMM D")}
                  <span style={{ color: "#CCC" }}>{` ● `}</span>
                  {order.fulfillment.time}
                </Typography>
              </Box>
              {order.fulfillment.type === "delivery" && (
                <Box>
                  <Typography
                    variant="overline"
                    style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                  >
                    <LocalShippingOutlinedIcon style={{ fontSize: "1.2rem" }} />
                    {order.fulfillment.type} <span style={{ color: "#CCC" }}>{` ● `}</span>
                    {order.fulfillment.provider && (
                      <Typography variant="overline" style={{ display: "flex", alignItems: "center", gap: "5px", textTransform: "uppercase" }}>
                        <Avatar
                          style={{ width: "20px", height: "20px" }}
                          src={`${STORAGE_BUCKET}/img/logo/${order.fulfillment.provider.name.toLowerCase()}.webp`}
                        ></Avatar>
                        {order.fulfillment.provider.name}
                        {order.fulfillment.provider.deliveryId ? <Check /> : <Cancel />}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
              {order.fulfillment.type === "pickup" && (
                <Box>
                  <Typography
                    variant="overline"
                    style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                  >
                    <LocalMallOutlinedIcon style={{ fontSize: "1.2rem" }} />
                    {order.fulfillment.type}
                    <span style={{ color: "#CCC" }}>{` ● `}</span>
                    {order.fulfillment.location ? order.fulfillment.location.street : order.fulfillment.address.split(",")[0]}
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography
                  variant="overline"
                  style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                >
                  <ReceiptLongOutlinedIcon style={{ fontSize: "1.2rem" }} />
                  {`${currency(order.src === "mkt" ? order.payment.bakerTotal : order.payment.total).format()}`}
                  {<span style={{ color: "#CCC" }}>{` ● `}</span>}
                  {order.items?.length} item(s)
                </Typography>
              </Box>
              {props.showTs && (
                <Typography
                  variant="overline"
                  style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                >
                  <AccessTimeOutlinedIcon style={{ fontSize: "1.2rem" }} />
                  Ordered {moment(order.ts._seconds * 1000).fromNow()}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "5px", justifyContent: "flex-start", alignItems: "center" }}>
                {order.paymentType === "offline" ? (
                  <MoneyOutlinedIcon sx={{ color: "#d63031" }} />
                ) : order.paymentType === "moto" ? (
                  <LocalPhoneIcon />
                ) : (
                  <CreditCardOutlinedIcon sx={{ color: "#00b894" }} />
                )}
                <Box>
                  <Box sx={{ fontSize: "12px" }}>{currency(order.payment.total, { precision: 0 }).format()} </Box>
                  <Box sx={{ fontSize: "9px", color: "#999" }}>({moment(order.ts._seconds * 1000).fromNow()})</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                {order.progress && <OrderProgress progress={order.progress} orderId={order.id} />}
                {printed && lastPrintTs && <OrderPrint order={order} />}
                {order.kitchen && <StickyNote2OutlinedIcon sx={{ color: "#999" }} />}
                {order.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                {order.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                <Avatar src={`${BAKER_PIC_FOLDER}/${order.baker.id}/logo400.webp`} style={{ width: "32px", height: "32px" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
