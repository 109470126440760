import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveDrawer from "./common/component/ResponsiveDrawer";
import { auth, getDeviceToken } from "./firebase";
import bkstApi from "./common/component/api";
import { THEME } from "./common/component/constants";
import Header from "./common/component/header";
import { getOS, getQueryParam, isPWA } from "./common/component/util";
import ConfigContext from "./common/component/context/ConfigContext";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import Login from "./common/screen/Login";
import Loans from "./loan/screen/Loans";
import LoanDetail from "./loan/component/LoanDetail";
import RequestDashboard from "./request/screen/RequestDashboard";
import OrderDashboard from "./order/screen/OrderDashboard";
import DailyReport from "./report/screen/DailyReport";
import ProductSearch from "./product/screen/ProductSearch";
import RequestSearch from "./request/screen/RequestSearch";
import AddQuote from "./quote/screen/AddQuote";
import Quote from "./quote/screen/Quote";
import Request from "./request/screen/Request";
import RequestDetail from "./request/screen/RequestDetail";
import OrderSearch from "./order/screen/OrderSearch";
import OrderPaymentEntry from "./order/screen/OrderPaymentEntry";
import OrderFulfillmentEntry from "./order/screen/OrderFulfillmentEntry";
import Order from "./order/screen/Order";
import Baker from "./baker/screen/Baker";
import BakerDetail from "./baker/screen/BakerDetail";
import Bakers from "./baker/screen/Bakers";
import BakerPricingModel from "./baker/screen/BakerPricingModel";
import OrderDetailsEntry from "./order/screen/OrderDetailsEntry";
import { BottomNavigation, BottomNavigationAction, Box, Hidden, LinearProgress, Paper } from "@mui/material";
import Notifications from "./notification/screen/Notifications";
import Customer from "./customer/screen/Customer";
import { Link as RouterLink } from "react-router-dom";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import BakerReport from "./baker/screen/BakerReport";
import Logins from "./baker/screen/Logins";
import ProductEntry from "./product/screen/ProductEntry";
import EditQuote from "./quote/screen/EditQuote";
import { APIProvider } from "@vis.gl/react-google-maps";
import Dashboard from "./dashboard/screen/Dashboard";

const theme = createTheme(THEME);

export default function App(props) {
  const navigate = useNavigate();

  const [ctx, setCtx] = useState();
  const [loading, setLoading] = useState(true);

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const me = () => {
    bkstApi(`/me`).then(async (res) => {
      const user = res.data.user;
      if (user) {
        setCtx(user);
        setLoading(false);

        const target = getQueryParam(window.location.search, "target");
        if (target) {
          navigate(target);
        }

        // check if PWA
        if (isPWA()) {
          //check if mobile
          const platform = getOS();
          if (platform === "iOS" || platform === "Android") {
            const token = await getDeviceToken();
            if (token) {
              bkstApi.post(`/fcm`, { token });
            }
          }
        }
      }
    });
  };

  const checkUser = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        me();
      } else {
        setLoading(false);
      }
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else if (ctx) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <APIProvider apiKey={"AIzaSyB-fYVyiXH2_vKis8ySShV-5mY33bQLXXI"}>
          <ConfigContext.Provider value={ctx}>
            <div style={{ display: "flex" }}>
              {ctx && <ResponsiveDrawer open={mobileDrawerOpen} toggle={() => setMobileDrawerOpen(!mobileDrawerOpen)} />}
              <main style={{ flexGrow: "1", overflowX: "hidden" }}>
                {ctx && <Header toggleMobileDrawer={() => setMobileDrawerOpen(!mobileDrawerOpen)} />}
                <Routes>
                  <Route path="/request" element={<RequestDashboard key={Date.now()} />} />
                  <Route path="/order" element={<OrderDashboard key={Date.now()} />} />
                  <Route path="/dashboard" element={<Dashboard key={Date.now()} />} />
                  <Route path="/request/search" element={<RequestSearch />} />
                  <Route path="/request/:reqId/quotes/add" element={<AddQuote />} />
                  <Route path="/request/:reqId/quotes/:quoteId/edit" element={<Quote />} />
                  <Route path="/request/:reqId/edit" element={<Request />} />
                  <Route path="/request/:reqId" element={<RequestDetail />} />
                  <Route path="/quote/:reqId/edit" element={<EditQuote />} />
                  <Route path="/quote/:reqId" element={<RequestDetail />} />
                  <Route path="/order/search" element={<OrderSearch />} />
                  <Route path="/order/:orderId/edit" element={<OrderDetailsEntry />} />
                  <Route path="/order/:orderId/payment/edit" element={<OrderPaymentEntry />} />
                  <Route path="/order/:orderId/fulfillment/edit" element={<OrderFulfillmentEntry />} />
                  <Route path="/order/:orderId" element={<Order />} />
                  <Route path="/baker/:bakerId/edit" element={<Baker />} />
                  <Route path="/baker/:bakerId/report" element={<BakerReport />} />
                  <Route path="/baker/search" element={<Bakers />} />
                  <Route path="/baker/:bakerId/config/pricing" element={<BakerPricingModel />} />
                  <Route path="/baker/:bakerId" element={<BakerDetail key={Date.now()} />} />
                  <Route path="/product/search" element={<ProductSearch />} />
                  <Route path="/site/:siteId/product/add" element={<ProductEntry />} />
                  <Route path="/site/:siteId/product/:productId" element={<ProductEntry />} />
                  <Route path="/report" element={<DailyReport />} />
                  <Route path="/loan/:loanId" element={<LoanDetail />} />
                  <Route path="/loan" element={<Loans />} />
                  <Route path="/login/reps" element={<Logins />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/customer/:customerId" element={<Customer />} />
                  <Route path="/order/:orderId" element={<Order />} />
                  <Route path="/requests/:reqId" element={<RequestDetail />} />
                  <Route path="*" element={<Navigate replace to="/dashboard" />} />
                </Routes>
                <Box my={10}></Box>
                <Hidden smUp>
                  <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation showLabels>
                      <BottomNavigationAction label="Quotes" icon={<LocalAtmOutlinedIcon />} to={`/request`} component={RouterLink} />
                      <BottomNavigationAction label="Orders" icon={<LocalMallOutlinedIcon />} to={`/order`} component={RouterLink} />
                      <BottomNavigationAction label="Loan" icon={<AccountBalanceOutlinedIcon />} to={`/loan`} component={RouterLink} />
                    </BottomNavigation>
                  </Paper>
                </Hidden>
              </main>
            </div>
          </ConfigContext.Provider>
        </APIProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </ThemeProvider>
    );
  }
}
